import type { RefObject } from 'react'
import { cx } from 'utils/cx'

import type { ClientTextLinkProps } from '../../../TextLink/client'

import styles from './CommBannerLinkWrapper.module.scss'

interface CommBannerLinkWrapperProps {
	tabIndex: -1 | undefined
	commRef: RefObject<HTMLDivElement | HTMLAnchorElement>
	colorStyle: {}
	wrapperClassName: string
	children: React.ReactNode
	firstLink?: ClientTextLinkProps
}
export function CommBannerLinkWrapper({
	tabIndex,
	commRef,
	colorStyle,
	wrapperClassName,
	children,
	firstLink,
}: CommBannerLinkWrapperProps) {
	const hasOnlyOneLink = firstLink?.href && firstLink?.children

	return hasOnlyOneLink ? (
		<a
			{...firstLink.linkProps}
			href={firstLink?.href}
			title={firstLink?.children as string}
			tabIndex={tabIndex}
			ref={commRef as RefObject<HTMLAnchorElement>}
			className={cx(wrapperClassName, styles.noUnderline)}
			style={colorStyle}
		>
			{children}
		</a>
	) : (
		<div
			ref={commRef as RefObject<HTMLDivElement>}
			className={wrapperClassName}
			style={colorStyle}
		>
			{children}
		</div>
	)
}
